import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import i18n from '../../i18n';
import Property from './Property';
import PropertyService from '../../services/PropertyService';
import classes from './styles.module.scss';
import { t } from '../../i18n';

export default function PropertyListMobile({
  buildType,
  title,
  searchKey,
  queryName,
  bedrooms,
  bathrooms,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  hasPool,
  type,
  region,
  floorCount,
  recent,
  action,
  searchTerm,
  titleTwo,
  ppid,
  filtersRef,
}) {
  const { data, refetch, isFetching } = useQuery([queryName], () =>
    PropertyService.getPropertyList({
      recent,
      bedrooms,
      bathrooms,
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      hasPool,
      type,
      region,
      floorCount,
      action,
      ppid,
      searchTerm,
      filters: filtersRef?.current,
    })
  );

  useEffect(() => {
    refetch();
  }, [searchKey, searchTerm]);

  return (
    <div className={classes.PropertyListMobile}>
      {title && (
        <h2>
          {i18n.language === 'tr' ? (
            <>
              <span>{titleTwo}</span> {title}
            </>
          ) : (
            <>
              {title} <span>{titleTwo}</span>
            </>
          )}
        </h2>
      )}

      <div className={classes.listContainer}>
        <ul className={classes.list}>
          <div className={classes.inner}>
            {data?.rows
              ?.filter(
                (property) => !buildType || property.buildingType === buildType
              )
              .map((property) => (
                <li key={property.id}>
                  <Property isFetching={isFetching} propertyData={property} />
                </li>
              ))}
          </div>
        </ul>
      </div>
    </div>
  );
}
