import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import i18n from '../../i18n';
import Property from './Property';
import PropertyService from '../../services/PropertyService';
import classes from './styles.module.scss';
import { t } from '../../i18n';

export default function PropertyList({
  buildType,
  title,
  filtersRef,
  searchKey,
  queryName,
  bedrooms,
  bathrooms,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  hasPool,
  type,
  region,
  floorCount,
  recent,
  action,
  searchTerm,
  titleTwo,
  ppid,
}) {
  const { data, refetch, isFetching } = useQuery([queryName], () =>
    PropertyService.getPropertyList({
      recent,
      bedrooms,
      bathrooms,
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      hasPool,
      type,
      region,
      floorCount,
      action,
      ppid,
      searchTerm,
      filters: filtersRef?.current,
    })
  );

  useEffect(() => {
    refetch();
  }, [searchKey, searchTerm]);

  return (
    <div className={classes.PropertyList}>
      {title && (
        <h2>
          {i18n.language === 'tr' ? (
            <>
              <span style={{ marginRight: '15px' }}>{titleTwo}</span> {title}
            </>
          ) : (
            <>
              {title} <span>{titleTwo}</span>
            </>
          )}
        </h2>
      )}

      <div className={classes.gridContainer}>
        {data?.rows
          ?.filter(
            (property) => !buildType || property.buildingType === buildType
          )
          .map((property) => (
            <div key={property.id} className={classes.gridItem}>
              <Property
                isFetching={isFetching}
                propertyData={property}
                propertyCount={data?.rows?.length}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
