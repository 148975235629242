import React from 'react';
import { useNavigate } from 'react-router-dom';
import MultiClamp from 'react-multi-clamp';
import i18n, { t } from '../../../i18n';
import { STATIC_URL } from '../../../constants/main';
import classes from './styles.module.scss';
import bathroomIcon from '../../../assets/images/icons/Bathroom.png';
import bedroomIcon from '../../../assets/images/icons/Bedroom.png';
import squareFeetIcon from '../../../assets/images/icons/square-feet.png';

export default function Property({
  propertyWidth,
  propertyCount,
  propertyData,
}) {
  const navigate = useNavigate();
  const title =
    i18n.language === 'en'
      ? propertyData.title || propertyData.titleRus
      : i18n.language === 'ru'
      ? propertyData.titleRus || propertyData.title
      : propertyData.titleTurkey || propertyData.title;

  const description =
    i18n.language === 'en'
      ? propertyData.description || propertyData.descriptionRus
      : i18n.language === 'ru'
      ? propertyData.descriptionRus || propertyData.description
      : propertyData.descriptionTurkey || propertyData.description;

  const { price } = propertyData;

  return (
    <div
      className={classes.Property}
      onClick={() => navigate(`/property/${propertyData.id}`)}
    >
      <div className={classes.price}>
        <span>£ {price.toLocaleString('en-US')}</span>
      </div>

      <header>
        <img
          src={`${STATIC_URL}${propertyData.Images?.[0]?.path}`}
          alt={title}
        />
      </header>

      <div className={classes.info}>
        <h1>
          <MultiClamp clamp={2}>{title}</MultiClamp>
        </h1>

        <div className={classes.buttonContainer}>
          <div className={classes.detailsButton}>
            <div className={classes.propertyContainer}>
              <img
                className={classes.propertyContainerIcon}
                src={bedroomIcon}
                alt="bedroom"
                width={20}
                height={20}
              />
              <div className={classes.propertyContainerText}>
                {propertyData.bedrooms} {t('Bedrooms')}
              </div>
            </div>
          </div>

          <div className={classes.detailsButton}>
            <div className={classes.propertyContainer}>
              <img
                className={classes.propertyContainerIcon}
                src={bathroomIcon}
                alt="bathroom"
                width={20}
                height={20}
              />
              <div className={classes.propertyContainerText}>
                {propertyData.bathrooms} {t('Bathrooms')}
              </div>
            </div>
          </div>

          <div className={classes.detailsButton}>
            <div className={classes.propertyContainer}>
              <img
                className={classes.propertyContainerIcon}
                src={squareFeetIcon}
                alt="square-feet"
                width={20}
                height={20}
              />
              <div className={classes.propertyContainerText}>
                {propertyData.squareFeet}m<sup>2</sup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
